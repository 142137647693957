html, body, #root, #root > div {
  min-height: 100vh;
  padding: 0;
}

body { overflow-x: hidden; }

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.login-bar {
  background-color: #999999;
  font-family: acumin-pro,sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 12px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.right-border {
  border-right: 1px solid
}

.search-row {
  display: inline;
}

.search{
  font-family: acumin-pro, sans-serif;
  font-weight: 500;
  font-style: italic;
  color: white;
  padding-top: 5px;
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: solid 1px white;
}

.search::placeholder{
  color: #bcbcbc;
}

.search:focus{
  font-style: normal;
  color: white;
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  border-bottom: solid 1px white;
}

.clickable {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.no-margin {
  margin: 0 !important;
}

.no-border {
  border: 0 !important;
}

.no-border > li > a {
  border: 0 !important;
}

.navbar-light .navbar-nav .nav-link {
  color: #eeeeee !important;
}

.landing-bg {
  height: 636px;
  background-image: url(https://s3-us-west-2.amazonaws.com/sonoritmobbpro/images/cq5dam.web.1280.1280.jpeg);
}

.white-text {
  color: white !important;
}

.welcome {
  padding: 10px;
}

.black-bg {
  background-color: black;
}

.white-bg {
  background-color: white !important;
}

.filter-bar {
  background-color: #fafafa;
}

.filter-bar::after{
  opacity: 0.2;
  background: url(https://i.imgur.com/k5s4tIV.png)
}

.product-item {
  margin: 2px;
  margin-right: 0px;
  min-height: 600px;
  background-color: white;
}

.with-margin-top {
  margin-top: 8px; 
}

.product-item:hover {
  border-color: #263238;
}

.text-align-left {
  text-align: left;
}

.responsive-image-wrapper {
  height: 300px;
}

.responsive-image {
  padding: 10px;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #000;

}

.add-margin-top {
  margin-top: 20px !important;
}

.hidden {
  display: none !important;
}

.show-detail {
  position: relative;
  font-weight: bold;
}

.show-detail > a {
  color: #000;
  padding: 5px 0px;
}

.show-detail:hover:after {
  width: 100%;
  right: 0;
}

.show-detail:after {
  background: none repeat scroll 0 0 transparent;
  bottom: 0;
  content: "";
  display: block;
  right: 0;
  position: absolute;
  background: black;
  width: 0; height: 2px;
  transition: width .5s ease;
  -webkit-transition: width .5s ease;
}

#item-modal-body  h2 {
  font-size: 20px;
}

.carousel {
  width:200px;
  height:200px;
  margin: auto;
}

.sonoritmo-bg-color {
  background-color: #282c34 !important;
  color: white !important;
}

.nav-item > i {
  height: 100%;
  width: 100%;
  text-align: center;
  line-height:100px;
}

.nav-item > input {
  margin-top: 30px;
}

.nav-item .active {
  color: #ccc;
  background-color: #262626;
}

@media (max-width: 812px) { 
  .nav-item > a {
    text-align: center;
    line-height: 100%
  }

  .navbar-toggler {
    background-color: white;
  }
}

.sonoritmo-bg {
  height:100%;
  background-color: white;
  background-repeat:no-repeat;
  background-size:cover;
  
}

.container-fluid,
.row-fluid { height: inherit; }

.no-padding {
  padding: 0 !important;
}
.carousel {
  width: 100%;
  height: 100%;
}

.fill{
  width:100%;
  height:90vh;
  background-position:center;
  background-size:cover;
}

.carousel-caption {
  bottom: 150px;
}

.carousel-indicators {
  bottom: 140px;
}

.filters-button {
  width: 100%;
  background-color: #E2E2E2 !important;
  border-radius: unset;
  border: none;
  color: #000;
}

.price-whitout-discount {
  font-size: 12px;
  text-decoration: line-through;
  color: gray;
}

.final-price {
  color: crimson;
}

#lblCartCount {
  font-size: 12px;
  background: #ff0000;
  color: #fff;
  padding: 0 5px;
  vertical-align: top;
  margin-left: -10px;
}

.badge {
padding-left: 9px !important;
padding-right: 9px !important;
-webkit-border-radius: 9px !important;
-moz-border-radius: 9px !important;
border-radius: 9px !important;
}

.label-warning[href],
.badge-warning[href] {
background-color: #c67605;
}

.kart {
  position: relative;
  top: 20%;
  margin: 0;
  padding: 0;
  line-height: 0 !important;
}

.kart:hover {
  background-color: transparent !important;
}

.products-content {
  min-height: 80vh;
}

.kart-item {
  background-color: white;
  margin-bottom: 7px;
  height: 100%;
}

.kart-item-photo {
  max-height: 80px;
  min-height: 80px;
  margin-right: 10px;
}

.purchase-summary {
  height: 33%;
  background-color: white;
  margin-bottom: 7px;
}

.small-text {
  font-size: 8px;
}

.center-text {
  text-align: center !important;
}

.border-right{
  border-right: solid 0vh white;
}

.border-left{
  border-left: solid 0vh white  !important;
}

.options {
  font-size: 12px;
  margin-left: 12px;
  margin-bottom: 5px;
}

.centered-content {
  align-items: center;
  justify-content: center;
}

.product-carousel > img {
  max-height: 40vh;
  min-height: 40vh;
  height: auto;
}

.text-justify > li {
  text-align: justify !important;
}

.main-content {
  padding: 5px;
}

.text-bolder {
  font-weight: 500;
}

.whatsapp-text {
  color: #25D366 !important;
}

.whatsapp-text > a {
  color: #25D366 !important;
}

.store-image {
  height: 33%;
}

.store-card-outer-div
{
  height: 40vh;
  overflow: hidden; 
}

.store-card-inner-div
{
  height: 100%;
  width: 100%;
  background-size: cover;
  background-position: center;
  transition: all 0.5s ease;
}

.store-card-inner-div > h1 {
  padding-top: 15vh;
  color: #ffff;
  text-align: center;
  vertical-align: middle;
}

.store-card-inner-div:hover
{
  transform: scale(1.2);
}

.stores-container > a:hover {
  text-decoration: none !important;
  color: white !important;
}

.store-carousel > img {
  max-height: 50vh;
  min-height: 50vh;
  width: auto;
}

.store-fill-image {
  width:100%;
  height:75vh;
  background-position:center;
  background-size:cover;
}

.store-address {
  padding-left: 0;
  padding-right: 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.store-address .button {
  margin-top: 40px;
}

@media (max-width: 812px) {
  .store-address {
    padding-left: 0;
    padding-right: 0;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .store-address .button {
    margin-top: 0px;
    margin-bottom: 5px;
  }
}

.iframe-maps iframe {
  width: 100% !important;
  height: 30vh !important;
}

.navbar-brand-sonoritmo {
  height: 100% !important;
  padding: 0 !important;
  margin: 0 !important;
  z-index: 10;
}

.logo-sonoritmo {
  padding: 0;
  margin: 0;
  height: 100px;
  width: auto;
}

@media (max-width: 812px) {
  .logo-sonoritmo {
    padding: 0;
    margin: 0;
    height: 50px;
    width: auto;
  }
}

.bg-gray {
  background-color: #fafafa !important;
}

.icon-success {
  color: #39b54a;
}

.icon-error {
  color: #d50000;
}

.icon-info {
  color: #2962ff;
}

.full-max-width {
  max-width: 100%;
}

.vertical-align {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.item-padding {
  padding-left: 25px;
  padding-right: 25px;
}

.products-bg {
  padding-top: 10px;
  background-color: #ebebec;
}

.with-margin-bottom {
  margin-bottom: 10px;
}

.carousel-indicators {
  display: none;
}

.mobile-nav {
  display: block;
  position: absolute;
  padding-left: calc(100% - 125px);
  top: 0;
}

.mobile-nav > div > a {
  float: right;
}

.navigation {
  padding: 0;
  margin: 0;
}

.navbar {
  padding: 0;
  margin: 0;
}

.display-block {
  display: block;
}

.search-item {
  padding-left: 30px; 
  width: calc(100% - 12rem);
  max-width: calc(100% - 12rem);
}

.search-item-mobile {
  width: 92%;
  max-width: 92%;
}

#search-input {
  width: 100%;
  background-color: #FFFFFF;
  color: #262626;
  border: none;
  padding: 0px;
  font-size: 12px;
  line-height: 1;
  height: 32px;
  margin: .8em 1em;
  padding-left: 5px;
  box-sizing: border-box;
  border-radius: 5px;
}

#search-input:hover {
  color: #262626 !important;
}

.with-padding-right {
  padding-right: 5px;
}

.image-centered {
  display: block;
  margin: auto;
  max-height: 35vh;
  min-width: 80vw;
}

.featured-items {
  display: block;
  margin: auto;
  width: 70vw;
}

@media (max-width: 720px) {
  .featured-items {
    width: 95vw;
  }
}

.featured-items-title {
  font-weight: 100;
  padding-top: 10px;
  padding-left: 30px;
  display: table-cell;
}

.sold-items {
  font-size: 14px;
  color: #666;
  line-height: 1;
}

.chat-button-circle {
  font-size: 26px;
}

.chat-input {
  width: 100% !important;
  padding-right: 40px !important;
}

.chat-send-icon {
  font-size: 52px;
}

.dropdown-menu {
  margin-top: 0px;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.owl-prev {
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: -40px;
  color: transparent;
  width: 0px;
}

.owl-prev::before {
  height: 35px;
  width: 22px;
  display: inline-block;
  content: "arrow";
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTQuMTkgMTYuMDA1bDcuODY5IDcuODY4LTIuMTI5IDIuMTI5LTkuOTk2LTkuOTk3TDE5LjkzNyA2LjAwMmwyLjEyNyAyLjEyOXoiLz48L3N2Zz4=');
}

.owl-next {
  position: absolute;
  top: 50%;
  right: 0px;
  color: transparent;
  width: 0px;
}

.owl-next::before {
  height: 35px;
  width: 22px;
  display: inline-block;
  content: "arrow";
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMiIgaGVpZ2h0PSIzMiIgdmlld0JveD0iMCAwIDMyIDMyIj48cGF0aCBkPSJNMTguNjI5IDE1Ljk5N2wtNy4wODMtNy4wODFMMTMuNDYyIDdsOC45OTcgOC45OTdMMTMuNDU3IDI1bC0xLjkxNi0xLjkxNnoiLz48L3N2Zz4=');
}

.owl-carousel .owl-item img {
  display: block !important;
  width: auto !important;
}

.fa-flip-horizontal { 
  transform: scaleX(-1);
 }